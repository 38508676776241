
  /* mobile navigation is in mobile.css file */

/*.logo img{
  object-fit: cover;
  height: auto;
  width: 400px;
  padding: 0 150px !important;
}*/

.logo{
  padding: 0 40px;
}

.upper-nav{
  /*position: relative;
  bottom: 22px;
  right: -57%;*/
  position: absolute;
  bottom: 52px;
  right: 50px;
  height: 27px;
  line-height: 3px;
}

.lower-nav{
  position: absolute;
  /*right: 14.5%;*/
  right: 48px;
  top: 35px;
}

.upper-nav .nav-item{
  font-size: 14px;
}

.lower-nav a{
  font-size: 16px;
  color: #0055a5 !important;
}

.lower-nav .nav-item .nav-link{
  padding: 0 8px;
}

.navbar{
  z-index: 99999;
  background: #FFF;
}
.navbar-expand-sm .navbar-nav .nav-link{
  /*padding-right: 1.5em;*/
  height: 30px;
}

.nav-item .nav-link a{
  text-align: center !important;
}

.upper-nav a{
  border-right: 1px solid #0055a5;
  font-family: 'PT Serif';
}

.upper-nav a:last-child{
  border: none;
}

.nav-link{
  padding: 0 10px !important;
}

.navi{
  z-index: 1000;
  padding-top: 10px;
}

.navi ul{
  margin-bottom: 10px;
}

nav ul {
	padding: 0;
  margin: 0;
	list-style: none;
	position: relative;
}

nav ul li {
	display:inline-block;
	/*background-color: #f3f3f3;*/
}

.navi > ul > li{
  /*padding: 0 10px;*/
  padding: 0 0;
  text-align: left;
}

.navi > ul > li:hover{
  border-bottom: 3px solid #355784;

  filter:alpha( opacity = 0 );
    -webkit-transition:300ms ease;
    -moz-transition:300ms ease;
    -o-transition:300ms ease;
    transition:border-bottom 0.300s ease-out 0.3s;
}

.navi > ul > li:hover > a{
  text-decoration: none;
}

nav a {
	display:block;
	/*padding:0 5px;*/
  padding:0 12px;
	color:#355784;
	font-size:16px;
	line-height: 28px;
	text-decoration:none;
}

nav ul li ul a:hover {
  text-decoration: none;
  /*background: #355784;*/
  background:#cecece;
  /*border-bottom: 3px solid #355784;*/
  filter:alpha( opacity = 0 );
    -webkit-transition:300ms ease;
    -moz-transition:300ms ease;
    -o-transition:300ms ease;
    transition:background 0.300s ease-out 0.3s;
}

nav ul ul {
	display: none;
	position: absolute;
	top: 31px;
}

/*nav ul ul:last-child{
  display: none;
  top:31px;
  position: absolute;
  left:-265px;
}*/

nav ul li:hover > ul {
	display:inherit;
}

nav ul ul li {
	width:265px;
	float:none;
	display:list-item;
	position:relative;
  background: #f3f3f3;
  height:32px;
  /*right: 104px;*/
}

nav ul ul ul li {
	position: relative;
	top:-31px;
	left:265px;
}

nav ul li:last-child {
  /*background:#fff;*/
}

#contactUs ul li {
  width: 265px;
  float: none;
  display: list-item;
  position: relative;
  background: #f3f3f3;
  height: 32px;
  right: 102px;
}

.active-link{
  border-bottom: 3px solid #fdb813 !important;
}


  /*.dropdown-submenu:hover>.dropdown-menu {
    display: block;
  }

  .dropdown-submenu > a:after {
    display: block;
    float: right;
    content: "?";
    color: #cccccc;


    content: " ";
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
    margin-top: 5px;
    margin-right: -10px;
  }

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px;
  }
  */

  .dropdown-submenu {
    position: relative;
  }

  .dropdown-submenu>a:after {
    content: "\f0da";
    float: right;
    border: none;
    font-family: 'FontAwesome';
  }

  .dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: 0px;
    margin-left: 0px;
  }
